import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Color } from "src/app/common/styles/ui/color";
import { Frame } from "src/app/common/styles/ui/frame";

@Component({
  selector: "frame-picker",
  templateUrl: "./frame-picker.component.html",
  styleUrls: ["./frame-picker.component.css"],
})
export class FramePickerComponent implements OnInit {
  public frames: Frame[] = [
    Frame.SURF_SPLASH,
    Frame.WHIRL_POOL,
    Frame.MINIMAL,
    Frame.WHIRL_POOL,
    Frame.SURF_SPLASH,
    Frame.MINIMAL,
    Frame.SURF_SPLASH,
    Frame.WHIRL_POOL,
    Frame.MINIMAL,
    Frame.WHIRL_POOL,
    Frame.SURF_SPLASH,
    Frame.MINIMAL,
  ];

  @Input() fillColor: Color;
  @Output() onFrameChange = new EventEmitter<Frame>();

  selectedFrame: Frame = Frame.SURF_SPLASH;

  constructor() {}

  ngOnInit() {}

  changeFrame(frame: Frame) {
    this.onFrameChange.emit(frame);
  }
}
