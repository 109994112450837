import { ActionBarComponent } from "./components/action-bar/action-bar.component";
import { RouterModule } from "@angular/router";
import { NgModule, NO_ERRORS_SCHEMA } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule } from "@angular/forms";
import { GlassModeComponent } from "./glass-mode/glass-mode.component";
import { ColorPickerComponent } from "./components/color-picker/color-picker.component";

import { CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { PinModeComponent } from "./pin-mode/pin-mode.component";
import { ToolbarComponent } from "./components/toolbar/toolbar.component";
import { SodapopComponent } from "./components/particles/sodapop/sodapop.component";
import { TextComponent } from "./components/text/text.component";
import { FramePickerComponent } from "./components/frame-picker/frame-picker.component";
import { MinimalComponent } from "./components/frames/minimal/minimal.component";
import { SplashComponent } from "./components/frames/splash/splash.component";
import { WhirlpoolComponent } from "./components/frames/whirlpool/whirlpool.component";
import { VacayComponent } from "./components/particles/vacay/vacay.component";
import { ParticlesPickerComponent } from "./components/particles-picker/particles-picker.component";
import { CaptureComponent } from "./components/capture/capture.component";
import { DescriptionComponent } from "./components/description/description.component";
import { BubbloComponent } from "./components/bubblo/bubblo.component";
import { TextKeyboardComponent } from "./components/text-keyboard/text-keyboard.component";
import { BubbloDetailsComponent } from "./components/bubblo-details/bubblo-details.component";
import { CommentsComponent } from "./components/comments/comments.component";
import { TimeagoModule } from "ngx-timeago";
import { FilterComponent } from "./components/filter/filter.component";
import { MyBubbloComponent } from "./components/my-bubblo/my-bubblo.component";
import { PermissionsComponent } from "./components/permissions/permissions.component";
@NgModule({
  declarations: [
    GlassModeComponent,
    PinModeComponent,
    ColorPickerComponent,
    ToolbarComponent,
    SodapopComponent,
    TextComponent,
    FramePickerComponent,
    MinimalComponent,
    SplashComponent,
    WhirlpoolComponent,
    VacayComponent,
    ParticlesPickerComponent,
    CaptureComponent,
    DescriptionComponent,
    BubbloComponent,
    TextKeyboardComponent,
    BubbloDetailsComponent,
    ActionBarComponent,
    CommentsComponent,
    FilterComponent,
    MyBubbloComponent,
    PermissionsComponent,
  ],
  imports: [CommonModule, RouterModule, FormsModule, TimeagoModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class BubbloWorldModule {}
