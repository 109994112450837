import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "particles-vacay",
  templateUrl: "./vacay.component.html",
  styleUrls: ["./vacay.component.css"],
})
export class VacayComponent implements OnInit {
  @Input() fillColor: string;
  constructor() {}

  ngOnInit() {}
}
