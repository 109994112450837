import { Bubblo } from "src/app/common/data/models/bubblo";
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
} from "@angular/core";

@Component({
  selector: "bubblo-details",
  templateUrl: "./bubblo-details.component.html",
  styleUrls: ["./bubblo-details.component.css"],
})
export class BubbloDetailsComponent {
  @Output() onClose = new EventEmitter();
  @Input() bubblo: Bubblo;
  commentMode: boolean;

  constructor(private cd: ChangeDetectorRef) {
    this.commentMode = false;
  }

  close() {
    this.onClose.emit();
  }

  openCommentMode() {
    this.commentMode = true;
    this.cd.detectChanges();
  }

  closeCommentMode() {
    this.commentMode = false;
    this.cd.detectChanges();
  }
}
