import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Color } from "src/app/common/styles/ui/color";
import { Particle } from "src/app/common/styles/ui/particle";

@Component({
  selector: "particles-picker",
  templateUrl: "./particles-picker.component.html",
  styleUrls: ["./particles-picker.component.css"],
})
export class ParticlesPickerComponent implements OnInit {
  public particleOptions: Particle[] = [
    Particle.SODAPOP,
    Particle.VACAY,
    Particle.SODAPOP,
    Particle.VACAY,
    Particle.SODAPOP,
    Particle.VACAY,
  ];

  @Input() fillColor: Color;
  @Output() onParticlesChange = new EventEmitter<Particle>();

  selectedParticles: Particle = Particle.SODAPOP;

  constructor() {}

  ngOnInit() {}

  changeParticles(particles: Particle) {
    this.onParticlesChange.emit(particles);
  }
}
