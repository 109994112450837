import { DOCUMENT } from "@angular/common";
import { Component, Inject, OnInit, Renderer2 } from "@angular/core";
import { DataService } from "./common/services/data.service";
import { PermissionsService } from "./common/services/permissions.service";
import { LocationService } from "./common/services/location.service";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent {
  title = "app";

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2,
    private data: DataService,
    private permissions: PermissionsService
  ) {}

  ngOnInit() {
    this.data.keyboardOpen.subscribe((keyboardOpen) =>
      keyboardOpen
        ? this.renderer.addClass(this.document.body, "keyboardOpen")
        : this.renderer.removeClass(this.document.body, "keyboardOpen")
    );
  }
}
