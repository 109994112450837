import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";

@Component({
  selector: "action-bar",
  templateUrl: "./action-bar.component.html",
  styleUrls: ["./action-bar.component.css"],
})
export class ActionBarComponent {
  @Input() likes: number;
  @Input() comments: number;
  @Output() onInputChange = new EventEmitter<string>();
  @Output() onSubmit = new EventEmitter<string>();
  @Output() like = new EventEmitter<void>();
  @Output() comment = new EventEmitter<void>();
  @Output() share = new EventEmitter<void>();
  @Output() report = new EventEmitter<void>();
  @Output() save = new EventEmitter<void>();
  @ViewChild("input", { static: false }) input: ElementRef;
  moreVisible: boolean = false;
  text: string;

  constructor(private cd: ChangeDetectorRef) {}

  onComment() {
    this.comment.emit();
  }

  onLike() {
    this.like.emit();
  }

  onShare() {
    this.share.emit();
  }

  onMore() {
    this.moreVisible = !this.moreVisible;
    this.cd.detectChanges();
  }

  onSave() {
    this.save.emit();
  }

  onReport() {
    this.report.emit();
  }
}
