import { Component, OnInit, Input, EventEmitter, Output } from "@angular/core";

@Component({
  selector: "text",
  templateUrl: "./text.component.html",
  styleUrls: ["./text.component.css"],
})
export class TextComponent implements OnInit {
  @Input() fillColor: string;
  @Input() value: string;

  @Output() onKeyboardToggle = new EventEmitter<boolean>();
  @Output() onSubmit = new EventEmitter<string>();

  text: string;
  keyboardOpen: boolean = false;

  constructor() {}

  ngOnInit() {}

  submit() {
    this.keyboardOpen = false;
    this.onSubmit.emit(this.text);
  }

  toggleKeyboard() {
    this.keyboardOpen = !this.keyboardOpen;
    this.onKeyboardToggle.emit(this.keyboardOpen);
  }
}
