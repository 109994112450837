import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "frame-whirlpool",
  templateUrl: "./whirlpool.component.html",
  styleUrls: ["./whirlpool.component.css"],
})
export class WhirlpoolComponent implements OnInit {
  @Input() fillColor: string;
  constructor() {}

  ngOnInit() {}
}
