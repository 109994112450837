import { Injectable } from "@angular/core";

import { Bubblo } from "../data/models/bubblo";
import { Frame } from "../styles/ui/frame";
import { Color } from "../styles/ui/color";
import { Particle } from "../styles/ui/particle";

declare var require: any;

@Injectable({
  providedIn: "root",
})
export class BubbloService {
  constructor() {}

  fetchBubblos = () => {
    const data: Bubblo[] = [
      {
        id: "1",
        user: {
          username: "nicolasdr94",
          avatar: "https://unavatar.io/twitter/donaldtrump",
        },
        createdAt: new Date(),
        updatedAt: new Date(),
        location: {
          lat: 51.171286,
          long: 3.860239,
          alt: 0,
        },
        likes: 55,
        fillColor: Color.GREEN,
        particles: null,
        frame: Frame.SURF_SPLASH,
        description:
          "If you organize your life around your passion ❤️, you can turn your passion into your story 📖 and then turn your story into something bigger.",
        img: require("../../../assets/example.png"),
        comments: [
          {
            username: "Lisa_95",
            comment:
              "I had no idea you were such a philosopher, Jay! 😇 Totally agree with what you’re saying though! 😘",
          },
          {
            username: "TheRealJoey",
            comment:
              "Those are some deep words, fam! 😎 Love the shades! We should grab a drink soon, it’s been too long! 🍺",
          },
        ],
        type: "public",
      },
      {
        id: "2",
        user: {
          username: "sammy777",
          avatar: "https://unavatar.io/twitter/timcook",
        },
        createdAt: new Date(),
        updatedAt: new Date(),
        location: {
          lat: 51.171068,
          long: 3.860185,
          alt: 0,
        },
        likes: 43,
        fillColor: Color.RED,
        particles: null,
        frame: Frame.MINIMAL,
        description:
          "If you organize your life around your passion ❤️, you can turn your passion into your story 📖 and then turn your story into something bigger.",
        img: require("../../../assets/example.png"),
        comments: [
          {
            username: "Lisa_95",
            comment:
              "I had no idea you were such a philosopher, Jay! 😇 Totally agree with what you’re saying though! 😘",
          },
          {
            username: "TheRealJoey",
            comment:
              "Those are some deep words, fam! 😎 Love the shades! We should grab a drink soon, it’s been too long! 🍺",
          },
        ],
        type: "friends",
      },
      {
        id: "3",
        user: {
          username: "mrBubblo",
          avatar: "https://unavatar.io/twitter/markzuckerberg",
        },
        createdAt: new Date(),
        updatedAt: new Date(),
        location: {
          lat: 51.173776,
          long: 3.935442,
          alt: 2,
        },
        likes: 15,
        fillColor: Color.BLACK,
        particles: null,
        frame: Frame.WHIRL_POOL,
        description:
          "If you organize your life around your passion ❤️, you can turn your passion into your story 📖 and then turn your story into something bigger.",
        img: require("../../../assets/example.png"),
        comments: [
          {
            username: "Lisa_95",
            comment:
              "I had no idea you were such a philosopher, Jay! 😇 Totally agree with what you’re saying though! 😘",
          },
          {
            username: "TheRealJoey",
            comment:
              "Those are some deep words, fam! 😎 Love the shades! We should grab a drink soon, it’s been too long! 🍺",
          },
        ],
        type: "friends",
      },
      {
        id: "4",
        user: {
          username: "mrBubblo",
          avatar: "https://unavatar.io/twitter/markzuckerberg",
        },
        createdAt: new Date(),
        updatedAt: new Date(),
        location: {
          lat: 51.171877,
          long: 3.861376,
          alt: 2,
        },
        likes: 15,
        fillColor: Color.BLACK,
        particles: null,
        frame: Frame.WHIRL_POOL,
        description:
          "If you organize your life around your passion ❤️, you can turn your passion into your story 📖 and then turn your story into something bigger.",
        img: require("../../../assets/example.png"),
        comments: [
          {
            username: "Lisa_95",
            comment:
              "I had no idea you were such a philosopher, Jay! 😇 Totally agree with what you’re saying though! 😘",
          },
          {
            username: "TheRealJoey",
            comment:
              "Those are some deep words, fam! 😎 Love the shades! We should grab a drink soon, it’s been too long! 🍺",
          },
        ],
        type: "friends",
      },
    ];

    return data;
  };
}
