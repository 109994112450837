import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { Bubblo } from "src/app/common/data/models/bubblo";
import { DataService } from "src/app/common/services/data.service";

@Component({
  selector: "bubblo",
  templateUrl: "./bubblo.component.html",
  styleUrls: ["./bubblo.component.css"],
})
export class BubbloComponent {
  @Input() bubblo: Bubblo;
  @Input() src: string;
  @Input() textMode: boolean = true;
  @Input() frameMode: boolean = true;
  @Output() onKeyboardToggle = new EventEmitter<boolean>();
  @ViewChild("imgContainer", { static: false }) container: ElementRef;
  videoSize: number;

  constructor(public data: DataService) {}

  ngAfterViewInit() {
    if (!this.data.bubbloSize) {
      this.data.bubbloSize = this.container.nativeElement.offsetWidth;
    }
  }

  toggleKeyboard(keyboardOpen: boolean) {
    this.onKeyboardToggle.emit(keyboardOpen);
  }
}
