import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  Output,
  EventEmitter,
  AfterViewInit,
  Renderer2,
  ChangeDetectorRef,
} from "@angular/core";
import { Bubblo } from "src/app/common/data/models/bubblo";
import { DataService } from "src/app/common/services/data.service";
import { Color } from "src/app/common/styles/ui/color";
import { Frame } from "src/app/common/styles/ui/frame";
import { Particle } from "src/app/common/styles/ui/particle";

@Component({
  selector: "pin-mode",
  templateUrl: "./pin-mode.component.html",
  styleUrls: ["./pin-mode.component.css"],
})
export class PinModeComponent implements OnInit, AfterViewInit {
  keyboardOpen: boolean = false;
  descriptionMode: boolean;
  showColorPicker: boolean;
  showFramePicker: boolean = true;
  showParticlesPicker: boolean = false;
  textMode: boolean = false;
  frameMode: boolean = true;
  @ViewChild("name", { static: false }) name: ElementRef;
  @ViewChild("pinmode", { static: false }) pinmode: ElementRef;
  bubblo: Bubblo;
  initialWindowHeight: number;
  pinModeHeight: string;

  @Output() onClose = new EventEmitter();

  constructor(
    private renderer: Renderer2,
    private data: DataService,
    private cdRef: ChangeDetectorRef
  ) {
    this.bubblo = new Bubblo(Frame.WHIRL_POOL, Particle.SODAPOP, Color.PURPLE);
  }

  ngAfterViewInit() {
    this.initialWindowHeight = window.innerHeight;
  }

  ngOnInit() {
    this.data.keyboardOpen.subscribe((keyboardOpen) =>
      keyboardOpen
        ? setTimeout(() => {
            this.pinModeHeight =
              this.initialWindowHeight - window.innerHeight + "px";
          }, 100)
        : (this.pinModeHeight = "100%")
    );
  }

  closeKeyboard() {
    this.textMode = false;
    this.showColorPicker = false;
    this.keyboardOpen = false;
  }

  toggleKeyboard(keyboardOpen: boolean) {
    this.keyboardOpen = keyboardOpen;
  }

  onSubmit() {
    this.keyboardOpen = false;
    this.descriptionMode = true;
    this.cdRef.detectChanges();
  }

  closePinMode() {
    this.onClose.emit();
  }

  toggleColorPicker() {
    this.showColorPicker = !this.showColorPicker;
    this.showParticlesPicker = false;
    this.showFramePicker = true;
  }

  toggleFramePicker(frameMode: boolean) {
    this.frameMode = frameMode;

    if (this.showColorPicker) {
      this.showColorPicker = !this.showColorPicker;
    }

    this.showFramePicker = !this.showFramePicker;
    this.showParticlesPicker = !this.showParticlesPicker;
  }

  toggleText() {
    this.textMode = !this.textMode;

    if (this.textMode) {
      this.bubblo.img = undefined;
    }

    this.showParticlesPicker = false;
    this.showFramePicker = true;

    if (this.showColorPicker) {
      this.showColorPicker = !this.showColorPicker;
    }
  }

  onColorChange(color: Color) {
    this.bubblo = { ...this.bubblo, fillColor: color };
  }

  onFrameChange(frame: Frame) {
    this.bubblo = { ...this.bubblo, frame: frame };
  }

  onParticlesChange(particles: Particle) {
    this.bubblo = { ...this.bubblo, particles: particles };
  }

  onInputChange(value: string) {
    this.bubblo = { ...this.bubblo, text: value };
  }

  onCapture(imgSrc: string) {
    this.bubblo = {
      ...this.bubblo,
      img: imgSrc,
    };
    this.onSubmit();
  }

  onRecord(vidSrc: string) {
    this.bubblo = { ...this.bubblo, video: vidSrc };
    this.cdRef.detectChanges();
    this.onSubmit();
  }
}
