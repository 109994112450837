import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class PermissionsService {
  geoPermission = <boolean>false;
  cameraPermission = <boolean>false;
  webXR = <boolean>false;

  changeWebXR(active: boolean) {
    this.webXR = active;
  }

  changeCameraPermission(active: boolean) {
    this.cameraPermission = active;
  }

  changeGeoPermission(active: boolean) {
    this.geoPermission = active;
  }
}
