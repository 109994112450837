import { Bubblo } from "src/app/common/data/models/bubblo";
import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";

@Component({
  selector: "comments",
  templateUrl: "./comments.component.html",
  styleUrls: ["./comments.component.css"],
})
export class CommentsComponent implements OnInit {
  @Output() onClose = new EventEmitter();
  @Output() onBack = new EventEmitter();
  @Input() bubblo: Bubblo;

  constructor() {}

  ngOnInit() {}

  close() {
    this.onClose.emit();
  }

  back() {
    this.onBack.emit();
  }
}
