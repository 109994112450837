import { Component, EventEmitter, OnInit, Output } from "@angular/core";

export enum options {
  PUBLIC = "public",
  FRIENDS = "friends",
  PERSONAL = "personal",
}

@Component({
  selector: "filter",
  templateUrl: "./filter.component.html",
  styleUrls: ["./filter.component.css"],
})
export class FilterComponent implements OnInit {
  showFilter: boolean;
  options: options[] = [options.PUBLIC, options.FRIENDS, options.PERSONAL];
  selected: options = options.PUBLIC;
  @Output() onChange = new EventEmitter<string>();

  constructor() {}

  ngOnInit() {}

  toggleFilter() {
    this.showFilter = !this.showFilter;
  }

  onSelect(option: options) {
    this.selected = option;
    this.showFilter = false;
    this.onChange.emit(option);
  }
}
