import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class DataService {
  private keyboardOpenSource = new BehaviorSubject<boolean>(false);
  bubbloSize: number;

  keyboardOpen = this.keyboardOpenSource.asObservable();

  constructor() {}

  changeKeyboardOpen(active: boolean) {
    this.keyboardOpenSource.next(active);
  }

  changeBubbloSize(size: number) {
    this.bubbloSize = size;
  }

  getBubbloSize() {
    return this.bubbloSize;
  }
}
