import { Component, Input, OnInit } from "@angular/core";

@Component({
  selector: "particles-minimal",
  templateUrl: "./minimal.component.html",
  styleUrls: ["./minimal.component.css"],
})
export class MinimalComponent implements OnInit {
  @Input() fillColor: string;
  constructor() {}

  ngOnInit() {}
}
