import { Component, Input, OnInit, Injectable } from "@angular/core";
import { Bubblo } from "src/app/common/data/models/bubblo";
import { LocationService } from "src/app/common/services/location.service";

@Component({
  selector: "description",
  templateUrl: "./description.component.html",
  styleUrls: ["./description.component.css"],
})
@Injectable()
export class DescriptionComponent implements OnInit {
  @Input() bubblo: Bubblo;
  constructor(private locationService: LocationService) {}

  ngOnInit() {}

  upload() {
    this.bubblo = { location: this.locationService.location, ...this.bubblo };
    alert(JSON.stringify(this.bubblo));
  }
}
