export enum Color {
  YELLOW = "#f9dd1d",
  ORANGE = "#f98f1d",
  RED = "#f91d1d",
  PINK = "#f91d75",
  PURPLE = "#8a1df9",
  BLUE = "#1d32f9",
  GREEN = "#1df960",
  BROWN = "#7c3a00",
  GRAY = "#3c3c3c",
  BLACK = "#0d0d0d",
}
