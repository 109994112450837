import { environment } from "./../../../environments/environment";
import { Component, OnInit, NgZone, HostListener } from "@angular/core";
import { Observable } from "rxjs";
import { Bubblo } from "src/app/common/data/models/bubblo";
import { BubbloService } from "src/app/common/services/bubblo.service";
import { animate, style, transition, trigger } from "@angular/animations";
import { DomSanitizer } from "@angular/platform-browser";
import { LocationService } from "src/app/common/services/location.service";

const AFRAME = (window as any).AFRAME;
const THREE = (window as any).THREE;

@Component({
  selector: "app-glass-mode",
  templateUrl: "./glass-mode.component.html",
  styleUrls: ["./glass-mode.component.css"],
  animations: [
    trigger("inOutAnimation", [
      transition(":enter", [
        style({ opacity: 0 }),
        animate(".2s ease-out", style({ opacity: 1 })),
      ]),
      transition(":leave", [
        style({ opacity: 1 }),
        animate(".2s ease-in", style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class GlassModeComponent implements OnInit {
  location?: Location;
  pinModeActive: boolean = false;
  detailsModeActive: boolean = false;
  bubblos: Bubblo[];
  bubblosOriginal: Bubblo[];
  bubblo: Bubblo;
  arjs: string;
  cameraModeFront: boolean = false;
  gpsUpdateStatus: string;
  isAndroid: boolean = false;
  mode: string = "public";
  geoPermission: boolean;
  cameraPermission: boolean;

  constructor(
    private bubbloService: BubbloService,
    private zone: NgZone,
    private locationService: LocationService
  ) {}

  ngOnInit() {
    var userAgent = navigator.userAgent || navigator.vendor;

    if (/android/i.test(userAgent)) {
      this.isAndroid = true;
    }

    if (!navigator.geolocation) {
      console.log("Location is not supported.");
      this.location = {
        lat: 0,
        long: 0,
      };
    }

    navigator.geolocation.getCurrentPosition(
      (pos) => {
        this.location = {
          lat: pos.coords.latitude + 0.0008,
          long: pos.coords.longitude - 0.0008,
        };
        console.log(pos.coords);
      },
      this.locationError,
      {
        enableHighAccuracy: true,
        timeout: 5000,
        maximumAge: 0,
      }
    );
  }

  async switchCamera() {
    var constraints = {
      video: {
        facingMode: !this.cameraModeFront ? "user" : "environment",
        aspectRatio: 16 / 9,
        width: { min: 1024, ideal: 1280, max: 1920 },
        height: { min: 576, ideal: 720, max: 1080 },
      },
      audio: false,
    };

    const video = <HTMLVideoElement>(
      Array.from(document.getElementsByTagName("video")).slice(-1)[0]
    );

    const stream = <MediaStream>video.srcObject;
    const tracks = stream.getTracks();

    tracks.forEach((track) => {
      track.stop();
    });

    navigator.mediaDevices
      .getUserMedia(constraints)
      .then((stream) => {
        const video = <HTMLVideoElement>(
          Array.from(document.getElementsByTagName("video")).slice(-1)[0]
        );
        video.srcObject = stream;
        video.play();
        this.cameraModeFront = !this.cameraModeFront;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  checkFarAway = () => {
    const bubblos = document.querySelectorAll("[gps-entity-place]");

    bubblos.forEach((x) => {
      if (!x.getAttribute("color")) {
        let distance = parseInt(x.getAttribute("distance"));
        let data = JSON.parse(x.getAttribute("data"));
        if (data) {
          let id = data.id;
          if (distance > 50) {
            x.setAttribute("src", "./assets/far.png");
          } else {
            x.setAttribute(
              "src",
              this.bubblos.filter((x) => x.id == id)[0].img
            );
          }
        }
      }
    });
  };

  updatePositionListener = (e: any) => {
    this.gpsUpdateStatus =
      "gps updated! - " +
      e.detail.position.longitude +
      ", " +
      e.detail.position.latitude;

    this.checkFarAway();

    setTimeout(() => {
      this.gpsUpdateStatus = "---";
    }, 2000);
  };

  ngAfterViewInit() {
    this.locationService.init();

    window.addEventListener("gps-camera-update-position", (e) =>
      this.updatePositionListener(e)
    );

    var handleClick = (e) => {
      this.bubblo = JSON.parse(e.srcElement.attributes.data.value);

      e.srcElement.play();

      this.zone.run(() => {
        this.openDetailsMode();
      });
    };

    AFRAME.registerComponent("vidhandler", {
      init: function () {
        var geometry = new THREE.CircleGeometry(10, 64);

        const video = document.getElementById("video");
        var texture = new THREE.VideoTexture(video);

        var material = new THREE.MeshBasicMaterial({
          side: THREE.DoubleSide,
          transparent: true,
          depthWrite: false,
          opacity: 1,
          map: texture,
        });
        var mesh = new THREE.Mesh(geometry, material);

        this.el.object3D.add(mesh);

        this.toggle = false;
        this.vid = document.querySelector("#video");
        this.vid.play();
      },
      tick: function () {
        if (this.el.object3D.visible == true) {
          if (!this.toggle) {
            this.toggle = true;
            this.vid.play();
          }
        } else {
          this.toggle = false;
          this.vid.pause();
        }
      },
    });

    AFRAME.registerComponent("clickhandler", {
      init: function () {
        this.el.addEventListener("click", handleClick, false);
      },
    });

    var data = this.bubbloService.fetchBubblos();
    this.bubblos = data.map((x) => {
      this.render(x).subscribe((res) => (x.img = res));
      console.log(x);
      return x;
    });
  }

  svgFileToString = async (url: string) => {
    const req = await fetch(url, { mode: "cors" });
    const svgString = await req.text();
    return svgString;
  };

  svgToDataURL = (svg: string) => {
    return "data:image/svg+xml;base64," + window.btoa(svg);
  };

  changeSvgColor = (input: string, color: string) => {
    const hexPattern = /(#[0-9a-f]{6})/gi;
    return input.replace(hexPattern, color);
  };

  render(bubblo: Bubblo): Observable<string> {
    return new Observable<string>((subscriber) => {
      const img = new Image();
      img.src = bubblo.img;

      const type = bubblo.frame ? "frames" : "particles";
      const name = bubblo.frame ? bubblo.frame : bubblo.particles;

      const frame = new Image();
      this.svgFileToString(`/assets/${type}/${name}.svg`).then((svg) => {
        const res = this.changeSvgColor(svg, bubblo.fillColor);
        frame.src = this.svgToDataURL(res);
      });

      const imgs = [img, frame];

      function imgLoaded(img) {
        return new Promise<void>((resolve) => {
          img.onload = () => resolve();
          img.onerror = () => resolve();
        });
      }

      Promise.all(imgs.map(imgLoaded)).then(() => {
        const size = img.width;
        const canvas = document.createElement("canvas");
        canvas.width = canvas.height = size;

        const ctx = canvas.getContext("2d");

        ctx.drawImage(img, 26, 26, size - 52, size - 52);
        ctx.drawImage(frame, 0, 0, size, size);

        const res = canvas.toDataURL("string");
        subscriber.next(res);
        subscriber.complete();
      });
    });
  }

  locationError() {
    //alert("geolocation failed");
  }

  openPinMode() {
    this.pinModeActive = true;
  }

  closePinMode() {
    this.pinModeActive = false;
  }

  openDetailsMode() {
    this.detailsModeActive = true;
  }

  closeDetailsMode() {
    this.detailsModeActive = false;
  }

  changeMode(value: string) {
    this.bubblos = this.bubblos.filter((x) => x.type == value.toLowerCase());

    console.log(value.toLowerCase());
  }

  numSequence(n: number): Array<number> {
    return Array(n);
  }
}

interface Location {
  lat: number;
  long: number;
}
