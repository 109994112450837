import { DomSanitizer } from "@angular/platform-browser";
import { Component, EventEmitter, OnInit, Output } from "@angular/core";

@Component({
  selector: "capture",
  templateUrl: "./capture.component.html",
  styleUrls: ["./capture.component.css"],
})
export class CaptureComponent implements OnInit {
  @Output() onCapture = new EventEmitter<string>();
  @Output() onRecord = new EventEmitter<string>();

  recordingHandler: any;
  recordingBusy: boolean;
  recorder: any;

  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit() {}

  async record() {
    const video = <HTMLVideoElement>(
      Array.from(document.getElementsByTagName("video")).slice(-1)[0]
    );
    const stream = <MediaStream>video.srcObject;
    this.recorder = new MediaRecorder(stream);

    this.recorder.ondataavailable = (event) => {
      let recording = <string>(
        this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(event.data))
      );

      this.onRecord.emit(recording);
    };
    this.recorder.start();
  }

  startRecording() {
    this.recordingHandler = setTimeout(() => {
      this.recordingBusy = true;
      this.record();
    }, 350);
  }

  stopRecording() {
    if (this.recorder) {
      this.recordingBusy = false;
      this.recorder.stop();
    } else {
      this.takeSnapshot();
    }
  }

  async takeSnapshot() {
    const canvas = document.createElement("canvas");
    const video = <HTMLVideoElement>document.getElementsByTagName("video")[0];

    const screenW = window.innerWidth;
    const screenH = window.innerHeight;

    canvas.width = screenW;
    canvas.height = screenH;

    const ctx = canvas.getContext("2d");
    ctx.drawImage(video, 0, 0, screenW, screenH);

    const videoImg = canvas.toDataURL("string");

    const x = screenW * 0.125;
    const y = screenH * 0.14;
    const w = screenW * 0.75;
    canvas.width = w;
    canvas.height = w;

    const img = new Image();
    img.src = videoImg;

    img.onload = () => {
      ctx.beginPath();
      ctx.arc(w / 2, w / 2, w / 2, 0, Math.PI * 2, true);
      ctx.clip();

      ctx.drawImage(img, x, y, w, w, 0, 0, w, w);

      const src = canvas.toDataURL("string");
      this.onCapture.emit(src);
    };
  }
}
