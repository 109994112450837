import { BubbloWorldModule } from "./bubblo-world/bubblo-world.module";
import { BrowserModule } from "@angular/platform-browser";
import { NgModule, NO_ERRORS_SCHEMA } from "@angular/core";
import { FormsModule } from "@angular/forms";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { RouterModule } from "@angular/router";

import { AppComponent } from "./app.component";
import { GlassModeComponent } from "./bubblo-world/glass-mode/glass-mode.component";
import { ApiAuthorizationModule } from "src/api-authorization/api-authorization.module";
import { AuthorizeInterceptor } from "src/api-authorization/authorize.interceptor";

import { CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";

import { TimeagoModule } from "ngx-timeago";
import { NgxImageCompressService } from "ngx-image-compress";

import { BrowserAnimationsModule } from "@angular/platform-browser/animations";

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: "ng-cli-universal" }),
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    BubbloWorldModule,
    ApiAuthorizationModule,
    TimeagoModule.forRoot(),
    RouterModule.forRoot([
      { path: "", component: GlassModeComponent, pathMatch: "full" },
    ]),
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthorizeInterceptor, multi: true },
    NgxImageCompressService,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
