import { Color } from "../../styles/ui/color";
import { Frame } from "../../styles/ui/frame";
import { Particle } from "../../styles/ui/particle";

interface Location {
  lat: number;
  long: number;
  alt?: number;
}

interface User {
  avatar: string;
  username: string;
}

interface Comment {
  username: string;
  comment: string;
}

export class Bubblo {
  id?: string;
  createdAt?: Date;
  updatedAt?: Date;
  description?: string;
  text?: string;
  img?: string;
  video?: string;
  location: Location;
  user: User;
  likes: number;
  comments: Comment[];
  type: string;

  constructor(
    public frame: Frame,
    public particles: Particle,
    public fillColor: Color
  ) {}
}
