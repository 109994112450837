import { Component, EventEmitter, Input, Output, OnInit } from "@angular/core";
import { Color } from "../../../common/styles/ui/color";

@Component({
  selector: "color-picker",
  templateUrl: "./color-picker.component.html",
  styleUrls: ["./color-picker.component.css"],
})
export class ColorPickerComponent implements OnInit {
  public colorOptions: Color[] = [
    Color.YELLOW,
    Color.ORANGE,
    Color.RED,
    Color.PINK,
    Color.PURPLE,
    Color.BLUE,
    Color.GREEN,
    Color.BROWN,
    Color.GRAY,
    Color.BLACK,
  ];
  @Output() onColorChange = new EventEmitter();
  selectedColor: Color = Color.PURPLE;

  constructor() {}

  ngOnInit() {}

  changeColor(color: Color) {
    this.selectedColor = color;
    this.onColorChange.emit(color);
  }
}
