import { Injectable } from "@angular/core";

interface Location {
  lat: number;
  long: number;
  alt?: number;
}

@Injectable({
  providedIn: "root",
})
export class LocationService {
  location: Location;

  constructor() {}

  init() {
    navigator.geolocation.watchPosition(
      (loc: any) => {
        this.location = {
          lat: loc.coords.latitude,
          long: loc.coords.longitude,
          alt: loc.coords.altitude,
        };
      },
      (err) => console.error(err),
      {
        enableHighAccuracy: true,
      }
    );
  }
}
