import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { DataService } from "src/app/common/services/data.service";

@Component({
  selector: "text-keyboard",
  templateUrl: "./text-keyboard.component.html",
  styleUrls: ["./text-keyboard.component.css"],
})
export class TextKeyboardComponent implements OnChanges, AfterViewInit {
  @Input() keyboardOpen: boolean;
  @Input() placeholder: string;
  @Output() onInputChange = new EventEmitter<string>();
  @Output() onSubmit = new EventEmitter<string>();
  @ViewChild("input", { static: false }) input: ElementRef;
  @ViewChild("container", { static: false }) container: ElementRef;
  open: boolean = false;
  text: string;
  initialWindowHeight: number;

  constructor(private data: DataService) {}

  ngOnChanges(changes: SimpleChanges) {
    this.toggleKeyboard(changes.keyboardOpen.currentValue);
  }

  @HostListener("touchmove", ["$event"])
  handleTouch(e) {
    this.open && e.preventDefault();
  }

  ngAfterViewInit() {
    this.initialWindowHeight = window.innerHeight;
  }

  onChange(value: string) {
    this.text = value;
    this.onInputChange.emit(value);
  }

  submit() {
    this.onSubmit.emit(this.text);
  }

  onFocus() {
    this.open = true;
    this.data.changeKeyboardOpen(true);
  }

  onFocusOut() {
    this.open = false;
    this.data.changeKeyboardOpen(false);
  }

  toggleKeyboard(keyboardOpen: boolean) {
    keyboardOpen && setTimeout(() => this.input.nativeElement.focus(), 0);
  }
}
