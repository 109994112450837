import { Component, OnInit } from "@angular/core";

@Component({
  selector: "my-bubblo",
  templateUrl: "./my-bubblo.component.html",
  styleUrls: ["./my-bubblo.component.css"],
})
export class MyBubbloComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
