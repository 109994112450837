import { Component, Input } from "@angular/core";

@Component({
  selector: "particles-sodapop",
  templateUrl: "./sodapop.component.html",
  styleUrls: ["./sodapop.component.css"],
})
export class SodapopComponent {
  @Input() fillColor: string;
}
