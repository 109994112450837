import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { Alert } from "selenium-webdriver";
import { XRSystem } from "webxr";
import { PermissionsService } from "src/app/common/services/permissions.service";

const xr = (navigator as any).xr as XRSystem;

export enum options {
  PUBLIC = "public",
  FRIENDS = "friends",
  PERSONAL = "personal",
}

@Component({
  selector: "permissions",
  templateUrl: "./permissions.component.html",
  styleUrls: ["./permissions.component.css"],
})
export class PermissionsComponent implements OnInit {
  showPermissions: boolean;
  options: options[] = [options.PUBLIC, options.FRIENDS, options.PERSONAL];
  selected: options = options.PUBLIC;
  @Output() onChange = new EventEmitter<string>();
  geoPermission: boolean = true;
  cameraPermission: boolean = false;
  webXR: boolean = true;

  ngOnInit() {
    this.checkCameraPermission();
    this.checkGeoPermission();
    this.checkWebXR();
  }

  constructor(private permissions: PermissionsService) {}

  checkWebXR() {
    console.log("checking webxr...");

    if (xr) {
      xr.isSessionSupported("immersive-vr").then((res) => console.log(res));
    }

    if (xr) {
      xr.isSessionSupported("immersive-vr")
        .then((isSupported) => {
          if (isSupported) {
            console.log("webxr supported!");
            this.webXR = true;
            /* userButton.addEventListener('click', onButtonClicked);
          userButton.textContent = 'Enter XR';
          userButton.disabled = false; */
          } else {
            console.log("webxr not supported!");
            this.webXR = false;
          }
        })
        .catch((e) => {
          console.log("webxr not supported!");
          this.webXR = false;
        });
    } else {
      console.log("webxr not supported!");
      this.webXR = false;
    }
  }

  checkCameraPermission() {
    // safari

    if (this.permissions.cameraPermission) {
      return;
    }

    navigator.mediaDevices
      .getUserMedia({ audio: false, video: true })
      .then((stream) => {
        /* use the stream */
        this.permissions.changeCameraPermission(true);
      })
      .catch((err) => {
        /* handle the error */
        this.permissions.changeCameraPermission(false);
      });

    // other browsers
    navigator.permissions &&
      navigator.permissions
        .query({ name: "camera" })
        .then((PermissionStatus) => {
          if (PermissionStatus.state == "granted") {
            this.permissions.changeCameraPermission(true);
          } else if (PermissionStatus.state == "prompt") {
            // prompt - not yet grated or denied
          } else {
            //denied
            this.permissions.changeCameraPermission(false);
          }
        });
  }

  checkGeoPermission() {
    // safari
    navigator.geolocation.getCurrentPosition(
      (pos) => {
        this.geoPermission = true;
      },
      (error) => {
        this.geoPermission = false;
      }
    );

    // other browsers
    navigator.permissions &&
      navigator.permissions
        .query({ name: "geolocation" })
        .then((PermissionStatus) => {
          if (PermissionStatus.state == "granted") {
            this.geoPermission = true;
          } else if (PermissionStatus.state == "prompt") {
            // prompt - not yet grated or denied
          } else {
            //denied
            this.geoPermission = false;
          }
        });
  }

  togglePermissions() {
    this.showPermissions = !this.showPermissions;
  }

  onSelect(option: options) {
    this.selected = option;
    this.showPermissions = false;
    this.onChange.emit(option);
  }
}
