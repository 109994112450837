import { Component, EventEmitter, OnInit, Output } from "@angular/core";

@Component({
  selector: "toolbar",
  templateUrl: "./toolbar.component.html",
  styleUrls: ["./toolbar.component.css"],
})
export class ToolbarComponent implements OnInit {
  @Output() editColor = new EventEmitter();
  @Output() editText = new EventEmitter();
  @Output() editFrame = new EventEmitter();
  frameMode: boolean = true;
  textMode: boolean = true;

  constructor() {}

  ngOnInit() {}

  toggleColor() {
    this.editColor.emit("");
  }

  toggleText() {
    this.textMode = !this.textMode;
    this.editText.emit("");
  }

  toggleFrame() {
    this.frameMode = !this.frameMode;
    this.editFrame.emit(this.frameMode);
  }
}
